import React from 'react'

import { Layout } from '../components/Layout'
import SEO from '../components/SEO/SEO'

import {
  Image, ImageContainer, Content, Title, Text, RedirectButton
} from '../styles/404'

import {
  Container
} from '../styles/commons'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <SEO pageTitle="404: Not found" description="This page could not be found." />
      <ImageContainer>
      </ImageContainer>
      <Content>
        <Title>PAGE NOT FOUND</Title>
        <Text>We searched high and low but couldn’t find what you’re looking for. <br/> Let’s find a better place for you to go.</Text>
        <RedirectButton href="/"> Go to the Home Page</RedirectButton>
      </Content>
    </Container>
  </Layout>
)

export default NotFoundPage
