import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  position:absolute;
  transform:translate(-365px);
  background: red;
  top:0; bottom:0; left:0;right:0;
  animation-duration: 4s;
`
